export const useSearchWishlist = () => {
  const state = useState<{
    houseId: number | null
    houseName: string
    iconic?: boolean
    onlineBookingAvailable?: boolean
    price?: number
  }>('search-wishlist', () => ({
    houseId: null,
    houseName: '',
    iconic: undefined,
    onlineBookingAvailable: undefined,
    price: undefined,
  }))

  const currentHouseFavoriteSelected = computed(() => state.value)

  const setCurrentHouseFavorite = (house: {
    id: number
    name: string
    iconicCollection: boolean
    onlineReservation?: boolean
    publicPrice?: number
  }) => {
    state.value = {
      houseId: house.id,
      houseName: house.name,
      iconic: house.iconicCollection,
      onlineBookingAvailable: house.onlineReservation,
      price: house.publicPrice,
    }
  }

  return {
    currentHouseFavoriteSelected,
    setCurrentHouseFavorite,
  }
}
